import React from "react";
import { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

    return (
      
    <div className="max-w-[1200px] mx-auto p-2 m-2s">
      <h1 className="text-3xl font-bold pt-3 text-center m-3">
        Privacy Policy
      </h1>
      <p />
      <p>
        <br />
        Privacy policy of use for Rakuza
        <br />
        Aqua Corporation (hereinafter referred to as the "Company") (hereinafter
        referred to as "the Company") will handle the personal information of
        users in the services provided by the Company (hereinafter referred to
        as "the Services"). (hereinafter referred to as the "Company") will
        handle personal information of users in accordance with the following
        privacy policy (hereinafter referred to as the "Policy") for the
        services provided by the Company (hereinafter referred to as the
        "Services"). (hereinafter referred to as the "Company") will strive to
        handle and protect personal information of users in accordance with the
        following privacy policy (hereinafter referred to as the "Policy").
      </p>
      <p>
        Definition and Acquisition of Personal Information
        <br />
        In this Policy, "Personal Information" means personal information as
        defined in Article 2.1 of the Personal Information Protection Law.
      </p>
      <p>
        In this policy, "personal information" means personal information as
        defined in Article 2.1 of the Personal Information Protection Law.
        <br />
        In this policy, "personal information requiring special consideration"
        means personal information requiring special consideration as defined in
        Article 2, Paragraph 3 of the Personal Information Protection Law, i.e.,
        personal information that requires special consideration in order to
        prevent unjust discrimination, prejudice, or other disadvantages to the
        person, such as race, creed, social status, medical history, criminal
        history, facts that have caused harm due to a crime, or other
        information that may be used in the handling of such information. (2)
        "Personal Information" means personal information that includes
        descriptions, etc. specified by the Personal Information Protection Law
        Enforcement Order as requiring special consideration in order to prevent
        unjust discrimination, prejudice, or other disadvantageous treatment of
        the individual.
        <br />
        In the process of providing this service, we will acquire personal
        information in an appropriate and lawful manner for the following
        purposes of use, in accordance with laws, regulations, and our rules and
        guidelines. In addition, we will endeavor to delete or dispose of any
        unneeded personal information in accordance with our regulations.
        Identification documents submitted by users will not be returned.
        <br />
        Purpose of Use
        <br />
        The specific purposes of use of user information related to the
        provision of this service are as follows
      </p>
      <p>
        To accept registrations, verify identity, authenticate users, record
        user preferences, and provide, maintain, protect, and improve the
        Service
        <br />
        To conduct surveys, marketing, research and analysis for the purpose of
        improving the quality of the Service
        <br />
        To respond to information, inquiries, etc. regarding the Service
        <br />
        To notify you of changes to our terms and conditions, policies, etc.
        (hereinafter referred to as "Terms and Conditions, etc.") regarding the
        Service To notify you of changes to the Terms and Conditions, policies,
        etc. (the "Terms and Conditions, etc.") regarding the Service
        <br />
        To respond to violations of the Terms of Service, etc.
        <br />
        To distribute and display advertisements and to measure their
        effectiveness
        <br />
        To contact you in case of emergency
        <br />
        To create backup data
        <br />
        For other purposes incidental to the above purposes of use
        <br />
        Security Management
        <br />
        In order to prevent falsification, loss, or leakage of personal
        information, the Company will improve the operation and maintenance of
        storage procedures and security of storage systems, instruct, manage,
        and supervise officers and employees, and take safety control measures
        for information. In the event that the Company outsources all or part of
        the handling of personal information, the Company will appropriately
        supervise the outsourced company to ensure that the personal information
        is securely managed.
      </p>
      <p>
        Provision of Personal Information to Third Parties
        <br />
        We will not provide personal information to third parties without
        obtaining the prior consent of the user. However, this shall not apply
        in the following cases
      </p>
      <p>
        When we outsource all or part of the handling of personal information
        within the scope necessary to achieve the purpose of use
        <br />
        When there is a risk of harm to the life, body, property, or other
        rights or interests of the user or a third party, and it is difficult to
        obtain the consent of the user
        <br />
        When the Company's business, including the provision of personal
        information, is succeeded due to a merger, corporate separation,
        transfer of business, or other reasons
        <br />
        Other cases permitted under the Personal Information Protection Law or
        other laws and regulations.
        <br />
        Disclosure of Personal Information
        <br />
        When a user requests us to notify the purpose of use, disclose, correct,
        add, delete, stop using, erase, or stop providing personal information
        (hereinafter referred to as "disclosure, etc.") in accordance with the
        provisions of the Personal Information Protection Law, we will disclose
        the personal information to the user in accordance with the following
        procedures. When we receive a request from a user for notification of
        the purpose of use, disclosure, correction, addition, deletion,
        suspension of use, elimination, or suspension of provision (hereinafter
        referred to as "Disclosure, etc.") of personal information in accordance
        with the provisions of the Personal Information Protection Law, we will
        disclose such information to the user within a reasonable period and to
        a reasonable extent after confirming that the request has been made by
        the user. However, this shall not apply to cases in which we are not
        obligated to disclose, etc. under the Personal Information Protection
        Law or other laws and regulations. Please note that a fee (2,000 yen per
        case) will be charged for the disclosure of notification of the purpose
        of use of personal information.
      </p>
      <p>
        Handling of Personal Information
        <br />
        When acquiring the personal information of a user, the Company shall
        obtain the consent of the user in advance, except in cases permitted
        under the Personal Information Protection Law or other laws and
        regulations.
      </p>
      <p>
        Use of Cookies and Other Technologies
        <br />
        The Company may use cookies and similar technologies to improve the
        Service. Users may disable cookies by changing the settings on their web
        browsers. However, if cookies are disabled, some features of the Service
        may not be available.
      </p>
      <p>
        Contact for Inquiries
        <br />
        Please direct any comments, questions, complaints, or other inquiries
        regarding the handling of user information to the following contact
        point.
      </p>
      <p>
        Personal Information Inquiry Desk
        <br />
        email : info@rakuichirakuza.io
        <br />
        Aqua Corporation
      </p>
      <p>
        Procedures for Changing the Privacy Policy
        <br />
        Except as otherwise provided by law, the Company may modify this Privacy
        Policy in a timely manner in accordance with the procedures prescribed
        by the Company, and any such modification will be announced by posting
        the modification on this page.
      </p>
      <p />
    </div>
  );
}
